<template>
  <a-spin :spinning="spinning">
    <!-- <react-table
      :table-data="tableData"
      :total-items="totalItems"
      :showTotal="showTotalFun"
      :columns="columns"
      :scroll="{ x: 0 }"
      :hideRowSelection="true"
      :is-full="true"
      :current-page="pageNumber"
      size="small"
      @emitRefreshData="clearFilterAndRefresh"
      @emitSelectChange="updateSelectChange"
      @emitOnPageChange="updatePageChange"
      @emitShowSizeChange="updateShowSizeChange"
    > -->
    <ele-table
      :columns="columns"
      :table-data="tableData"
      :total-items="totalItems"
      :is-full="true"
      :current-page="pageNumber"
      :hide-row-selection="true"
      @emitRefreshData="clearFilterAndRefresh"
      @emitSelectChange="updateSelectChange"
      @emitOnPageChange="updatePageChange"
      @emitShowSizeChange="updateShowSizeChange"
    >
      <a-row :gutter="8">
        <a-col class="gutter-row" :span="6">
          <a-select
            v-model="SearchDto.CompanyId"
            show-search
            :filter-option="filterOption"
            style="width: 100%"
            placeholder="签约公司"
            allowClear
          >
            <a-select-option
              v-for="item in PayCompanys"
              :key="item.value"
              :value="item.value"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-col>
        <a-col class="gutter-row" :span="3">
          <a-month-picker
            v-model="SearchDto.YearMonth"
            valueFormat="YYYY-MM"
            :placeholder="l('开票月份')"
            style="width: 100%"
          />
        </a-col>
        <a-col class="gutter-row" :span="3">
          <a-select
            v-model="SearchDto.Type"
            show-search
            :filter-option="filterOption"
            style="width: 100%"
            placeholder="开票类型"
            allowClear
          >
            <a-select-option
              v-for="item in Invoicetype"
              :key="item.value"
              :value="item.value"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-col>
        <a-col class="gutter-row" :span="4">
          <a-button
            type="primary"
            @click="searchData"
            v-if="isGranted('Pages.CompanyInvoicPermissions.Query')"
          >
            {{ l("Search") }}
          </a-button>
          <a-button @click="clearAndGetData"> {{ l("Reset") }}</a-button>
        </a-col>
        <a-col class="gutter-row" style="text-align: right" :span="8">
          <a-button
            v-if="isGranted('Pages.CompanyInvoicPermissions.Export')"
            @click="exportToExcel()"
          >
            <a-icon type="file-excel" />
            <span>{{ l("Export") }}</span>
          </a-button>
        </a-col>
      </a-row>
    </ele-table>
  </a-spin>
</template>
<script>
import { AppComponentBase } from "@/shared/component-base";
import { ModalHelper } from "@/shared/helpers";
import moment from "moment";
import EleTable from "@/components/ele-table";
import SalaryDetail from "@/components/salary/salarydetail.vue";
import { fileDownloadService } from "@/shared/utils";
import SelectPageList from "@/components/linggong/select-page-list";
import { CommonServiceProxy, initInvoice } from "@/shared/common-service";

export default {
  name: "settlementModule",
  mixins: [AppComponentBase],
  components: { EleTable },
  data() {
    return {
      spinning: false,
      SearchDto: {
        // ProjectId: "",
        // ProjectName: "",
        // CustomerId: "",
        YearMonth: "",
        Type: undefined,
        CompanyId: undefined,
        SkipCount: 0,
        MaxResultCount: 50,
      },
      commonService: null,
      totalItems: 0,
      pageNumber: 1,
      pagerange: [1, 1],
      totalPages: 1,
      selectedRowKeys: [],
      selectedRows: [],
      tableData: [],
      columns: [],
      PayCompanys: [], // { value: "", label: "签约公司" }
      Invoicetype: [
        // { value: "", label: "开票类型" },
        { value: 1, label: "增值税普通发票" },
        { value: 2, label: "增值税专用发票" },
      ],
    };
  },
  methods: {
    initColumns() {
      var vm = this;
      this.columns = [
        {
          title: "签约公司",
          dataIndex: "companyName",
          align: "center",
          // width: 100,
          scopedSlots: { customRender: "companyName" },
        },
        {
          title: "开票月份",
          dataIndex: "yearMonth",
          align: "center",
          // width: 100,
          scopedSlots: { customRender: "yearMonth" },
        },
        {
          title: "总开票金额/月",
          dataIndex: "monthTotalMoney",
          align: "center",
          // width: 100,
          customRender: (text) => {
            return `￥${text}`;
          },
        },
        {
          title: "开票类型",
          dataIndex: "type",
          align: "center",
          // width: 100,
          scopedSlots: { customRender: "type" },
          customRender: (text) => {
            if (text == 1) {
              return "增值税普通发票";
            }
            if (text == 2) {
              return "增值税专用发票";
            }
            return "";
          },
        },
      ];
    },
    /**
     * ������
     * @param input
     * @param option
     */
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    /**
     * ��ȡ���з��Ź�˾
     * */
    getAllPayCompany() {
      this.spinning = true;
      this.$api
        .request({
          url: this.$apiUrl + "/api/services/app/Organization/GetAllSelectList",
          method: "GET",
        })
        .then((res) => {
          this.PayCompanys.splice(0, this.PayCompanys.length);
          if (res && res.data) {
            this.PayCompanys = res.data.map((item) => {
              return {
                value: item.value,
                label: item.text,
              };
            });
          }
        })
        .catch((e) => {
          abp.message.error(this.l("Error500Desc"));
          console.error(e);
        })
        .finally(() => {
          this.spinning = false;
        });
    },
    /**
     * ��ȡ���� */
    getData() {
      this.spinning = true;
      let options = {
        url:
          this.$apiUrl + "/api/services/app/Receipt/GetSiginCompanyInvoicePage",
        method: "GET",
        params: {
          yearMonth:
            this.SearchDto.YearMonth == "" ? null : this.SearchDto.YearMonth,
          type: this.SearchDto.Type == "" ? null : this.SearchDto.Type,
          companyId:
            this.SearchDto.CompanyId == "" ? null : this.SearchDto.CompanyId,
          skipCount: this.SearchDto.SkipCount,
          maxResultCount: this.SearchDto.MaxResultCount,
        },
      };
      this.$api
        .request(options)
        .then((res) => {
          if (res.status == 200) {
            let data = res.data;
            this.totalPages = Math.ceil(
              data.totalCount / this.SearchDto.MaxResultCount
            );
            this.totalItems = data.totalCount;
            this.pagerange = [
              (this.pageNumber - 1) * this.SearchDto.MaxResultCount + 1,
              this.pageNumber * this.SearchDto.MaxResultCount,
            ];
            this.tableData = data.items.map((item) => {
              return {
                ...item,
                type: initInvoice(item.type),
              };
            });
          }
        })
        .catch((e) => {
          abp.message.error(this.l("Error500Desc"));
          console.error(e);
        })
        .finally(() => {
          this.spinning = false;
        });
    },
    searchData() {
      this.SearchDto.SkipCount = 0;
      this.getData();
    },
    /**
     * 导出为excel
     */
    async exportToExcel() {
      this.spinning = true;
      try {
        let res = await this.commonService.get({
          url: "/api/services/app/Receipt/GetToSiginCompanyInvoiceExcelFile",
          params: {
            yearMonth:
              this.SearchDto.YearMonth == "" ? null : this.SearchDto.YearMonth,
            type: this.SearchDto.Type == "" ? null : this.SearchDto.Type,
            companyId:
              this.SearchDto.CompanyId == "" ? null : this.SearchDto.CompanyId,
            skipCount: this.SearchDto.SkipCount,
            maxResultCount: this.SearchDto.MaxResultCount,
          },
        });
        console.log(res);
        fileDownloadService.downloadTempFile(res);
        this.spinning = false;
      } catch (error) {
        console.log(error);
        this.spinning = false;
      }
    },
    clearFilterAndRefresh() {
      this.SearchDto.SkipCount = 0;
      this.getData();
    },
    // ��ҳ�¼�
    showTotalFun() {
      return this.l(
        "GridFooterDisplayText",
        this.pageNumber,
        this.totalPages,
        this.totalItems,
        this.pagerange[0],
        this.pagerange[1]
      );
    },
    updateShowSizeChange(newV) {
      let { current, size } = newV;
      this.pageNumber = 1;
      this.SearchDto.SkipCount = 0;
      this.SearchDto.MaxResultCount = size;
      this.getData();
    },
    // tableѡ���¼�
    updateSelectChange(newV) {
      let { selectedRowKeys, selectedRows } = newV;
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
    },
    // ��ҳ
    updatePageChange(newV) {
      let { page, pageSize, skipCount } = newV;
      this.pageNumber = page;
      this.SearchDto.SkipCount = (page - 1) * pageSize;
      this.SearchDto.MaxResultCount = pageSize;
      this.getData();
    },
    clearAndGetData() {
      //   this.SearchDto.ProjectId = "";
      //   this.SearchDto.ProjectName = "";
      //   this.SearchDto.CustomerId = "";
      this.SearchDto.YearMonth = "";
      this.SearchDto.Type = undefined;
      this.SearchDto.CompanyId = undefined;
      this.SearchDto.SkipCount = 0;
      this.pageNumber = 1;
      this.getData();
    },
  },
  created() {
    this.commonService = new CommonServiceProxy(this.$apiUrl, this.$api);
    this.initColumns();
    this.getAllPayCompany();
    this.getData();
  },
};
</script>
<style lang="less" scoped>
.btn--container {
  margin-top: 20px;
}

.btn--footer {
  margin-bottom: 20px;
}
</style>
